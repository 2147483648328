import React, { useEffect, useState } from 'react';
import './main.css';
import Header from '../shared/parts/header';
import Sidebar from '../shared/parts/sidebar';
import Footer from '../shared/parts/footer';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from "aws-amplify";
import {
  connectSocialAccount,
  getUserId,
  getUserRole,
  loginUser,
  updateUserMetadata
} from '../shared/store/user.reducer';
import { ApiClient } from '../shared/api_client';
import { getSelectedClient, selectClient, setClients } from '../shared/store/manager.reducer';
import Profile from '../shared/parts/profile/profile';

const Main: React.FC = () => {
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const userRole = useSelector(getUserRole);
  const selectedClient = useSelector(getSelectedClient);

  const [managedUser, setManagedUser]: any = useState();
  const [userProfile, setUserProfile]: any = useState(null);

  useEffect(() => {
    getConnectedUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userRole === 'manager' && selectedClient) {
      setManagedUser(selectedClient);
    } else if (userId) {
      setManagedUser(userId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, userId]);

  useEffect(() => {
    if (managedUser) {
      getSocialMediaAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managedUser]);

  const getSocialMediaAccounts = () => {
    ApiClient.getSocialMediaAccounts(managedUser).then(resp => {
      const socialAccounts = resp.data;
      socialAccounts.forEach((account: any) => {
        dispatch(connectSocialAccount(account));
      })
    });
  }

  const getConnectedUser = () => {
    Auth.currentAuthenticatedUser()
      .then((user: any) => {
        dispatch(loginUser({
          email: user.attributes.email,
          userId: user.username,
          isLogged: true
        }))
        ApiClient.getUserMetadata(user.username).then(async (resp: any) => {
          const {accountType, tokens, latestSubscriptionId, subscriptionEnd, subscriptionAutoRenewal, role, clients, profile} = resp.data;
          setUserProfile(profile);
          dispatch(updateUserMetadata({
            accountType,
            tokens,
            latestSubscriptionId,
            subscriptionEnd,
            subscriptionAutoRenewal,
            role,
            clients,
            profile: profile
          }));

          if (role === 'manager') {
            const managedClients: any = [];
            const clientsRequests = await Promise.allSettled(clients.map((clientId: string) => ApiClient.getUserMetadata(clientId)));
            clientsRequests.forEach((request: any) => {
              if (request.status === 'fulfilled') {
                managedClients.push(request.value.data);
              }
            });
            dispatch(setClients({
              clients: managedClients
            }));
            dispatch(selectClient({
              clientId: user.username
            }));
          }
        });
      })
      .catch(err => {
        // TODO Disconnect and clear user stored values
      });
  }

  return (
    <>
      <Sidebar/>
      <div id="main-content">
        <Header/>
        <div className="flex-fill d-grid">
          <Outlet/>
          {
            userProfile === undefined &&
            <Profile isVisible={true} onHide={() => {}} />
          }
        </div>
        <Footer/>
      </div>
    </>
  );
};

export default Main;