import React, { useEffect, useState } from 'react';
import './payment_page.css';
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { ApiClient } from '../shared/api_client';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../shared/store/user.reducer';
import { Link, useNavigate } from 'react-router-dom';
import { toggleMainLoader } from '../shared/store/app.reducer';
import { useTranslation } from 'react-i18next';

export default function PaymentPage({darkMode}: {darkMode?: boolean}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {i18n, t} = useTranslation();

  const user = useSelector(getUser);
  const [pagePart, setPagePart]: [any, any] = useState('');

  const initCheckout = (paymentPlan: string) => {
    if (!user.userId || user.accountType === 'trial') {
      navigate('/app/payment');
      return
    }

    dispatch(toggleMainLoader({
      isVisible: true
    }))
    ApiClient.getPaymentUrl(user.userId, paymentPlan)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(err => {
        console.error(err);
      }).finally(() => {
      dispatch(toggleMainLoader({
        isVisible: true
      }));
    })
  }

  const renderPaymentSuccess = () => {
    return (
      <div className="payment-callback success text-center">
        <div className="d-inline-block position-relative my-5">
          <MDBIcon className="icon text-success" fas icon="check-circle"/>
          <div className="circles">
            <div className="circle1 bg-primary"></div>
            <div className="circle2 bg-primary"></div>
            <div className="circle3 bg-primary"></div>
          </div>
        </div>
        <h3 className="text-success mt-3">
          {t('subscription.paymentSuccessful')}
        </h3>
        <p className="mt-3">
          {t('subscription.paymentSuccessfulDescription')}
        </p>
        <Link to="/app/new-post">
          <MDBBtn id="generate-post" className="mx-auto d-flex fw-bold mt-5" size="lg" color="primary">
            <span className="flex-fill text-center">
              {t('subscription.startPosting')}
            </span>
          </MDBBtn>
        </Link>
      </div>
    )
  }

  const renderPaymentFailure = () => {
    return (
      <div className="payment-callback error text-center">
        <div className="d-inline-block position-relative my-5">
          <MDBIcon className="icon text-danger" fas icon="times-circle"/>
          <div className="circles">
            <div className="circle1 bg-danger"></div>
            <div className="circle2 bg-danger"></div>
            <div className="circle3 bg-danger"></div>
          </div>
        </div>
        <h3 className="text-danger mt-3">
          {t('subscription.paymentFailed')}
        </h3>
        <p className="mt-3 mb-0 w-space-pre-line">
          {t('subscription.paymentError')}
        </p>
        <Link to="#">
          <MDBBtn id="generate-post" className="mx-auto d-flex fw-bold mt-5" size="lg" color="primary">
            <span className="flex-fill text-center">
              {t('shared.contactUs')}
            </span>
          </MDBBtn>
        </Link>
      </div>
    )
  }

  const renderPaymentPlans = () => {
    return (
      <>
        <MDBRow>
          <MDBCol md="12">
            <h2 className="text-center fs-1 mb-5">
              {t('subscription.title')}
            </h2>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="4">
            <MDBCard className="pricing-card h-100 p-5 plans plan-1">
              <MDBCardBody>
                <h3>
                  {t('subscription.plan1.title')}
                </h3>
                <h3>
                  {t('subscription.plan1.duration')}
                </h3>
                <p className="price">
                  {t('subscription.free')}
                </p>
                <span className="billing-info">
                {t('subscription.plan1.paymentPolicy')}
              </span>
                {(user?.userId &&
                  <MDBBtn className="cta-btn current-plan" rounded size="lg" disabled>
                    {t('subscription.currentPlan')}
                  </MDBBtn>
                )}
                {(!user?.userId &&
                  <MDBBtn className="cta-btn" rounded size="lg" onClick={() => navigate('/app/payment')}>
                    {t('subscription.join')}
                  </MDBBtn>
                )}
                <ul className="features">
                  <li>
                    {t('subscription.plan1.description')}
                  </li>
                </ul>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="4">
            <MDBCard className="pricing-card h-100 p-5 plans plan-2">
              <MDBCardBody>
                <h3>
                  {t('subscription.plan2.title')}
                </h3>
                <h3>
                  {t('subscription.plan2.duration')}
                </h3>
                <p className="price">999<span className="unit">€</span></p>
                <span className="billing-info">
                {t('subscription.plan2.paymentPolicy')}
              </span>
                <MDBBtn className="cta-btn" rounded size="lg" onClick={() => initCheckout('standard_monthly')}>
                  {t('subscription.join')}
                </MDBBtn>
                <ul className="features">
                  <li>
                    {t('subscription.plan2.description')}
                  </li>
                </ul>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol md="4">
            <MDBCard className="pricing-card h-100 p-5 plans plan-3">
              <MDBCardBody>
                <h3>
                  {t('subscription.plan3.title')}
                </h3>
                <h3>
                  {t('subscription.plan3.duration')}
                </h3>
                <p className="price"><span>999<span className="unit">€</span></span></p>
                <span className="billing-info">
                {t('subscription.plan3.paymentPolicy')}
              </span>
                <MDBBtn className="cta-btn" rounded size="lg" onClick={() => initCheckout('standard_yearly')}>
                  {t('subscription.join')}
                </MDBBtn>
                <ul className="features">
                  <li>
                    {t('subscription.plan3.description')}
                  </li>
                </ul>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </>
    )
  }

  const handlePaymentCallback = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const success: any = queryParams.get('success');

    switch (success) {
      case 'true':
        setPagePart(renderPaymentSuccess());
        break;
      case 'false':
        setPagePart(renderPaymentFailure());
        break;
      default:
        setPagePart(renderPaymentPlans());
    }
  }

  useEffect(() => {
    handlePaymentCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, user.userId])

  return (
    <div id="pricing" className={`pb-5 ${darkMode ? 'dark': 'light'}`}>
      <MDBContainer className="p-4">
        {pagePart}
      </MDBContainer>
    </div>
  )
}
