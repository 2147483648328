import React from 'react';
import { MDBBtn, MDBIcon, MDBNavbar } from 'mdb-react-ui-kit';
import './header.css';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUser } from "../store/user.reducer";
import { useTranslation } from 'react-i18next';
import ClientSelector from './client_selector/client_selector';

const Header = () => {

  const user = useSelector(getUser);

  const { t, i18n } = useTranslation();

  const subscriptionRemainingTime = (subscriptionEndDate: number, isTrial: boolean) => {
    const now = new Date().getTime();
    const messagePrefix = isTrial ? t('header.trialExpiresOn') : t('header.membershipExpiresOn');
    if (subscriptionEndDate * 1000 > now) {
      return `${messagePrefix} ${new Date(subscriptionEndDate * 1000).toLocaleDateString()}`;
    } else {
      return isTrial ? t('header.trialExpired') : t('header.membershipExpired');
    }

  }

  return (
    <MDBNavbar id="header" dark expand="md" className="shadow-custom ps-0">
      <div className="h-100 d-flex align-items-center p-0">
        <MDBBtn noRipple className="shadow-0 d-flex align-items-center" onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'fr' : 'en')}>
          <img className="me-2 i18n-flag" src={`${process.env.PUBLIC_URL}/assets/images/${i18n.language === 'en' ? 'french-flag.svg' : 'uk-flag.svg'}`} alt="language-flag" />
          {i18n.language === 'en' ? 'Français' : 'English'}
        </MDBBtn>
      </div>

      <div className="mx-3 d-flex align-items-center flex-column flex-grow-1">
        <ClientSelector />
      </div>

      <div className="d-flex flex-column align-items-end ">

      {user.accountType && user.accountType === 'premium' && user.subscriptionEnd > (new Date()).getTime() / 1000 &&
        <div className="d-flex align-items-center">
          <div className="d-flex flex-column">
            <span
              className="text-white fw-bold me-3 fs-7">{subscriptionRemainingTime(user.subscriptionEnd, false)}</span>
            <span className="text-white fw-bold me-3 fs-7 text-end">{t('header.aiTokens')} ∞</span>
          </div>
        </div>
      }

      {user.accountType && (user.accountType !== 'premium' || (user.accountType === 'premium' && user.subscriptionEnd <= (new Date()).getTime() / 1000)) &&
        <div className="d-flex align-items-center">
          <div className="d-flex flex-column">
            <span
              className="text-white fw-bold me-3 fs-7">{subscriptionRemainingTime(user.subscriptionEnd, user.accountType === 'trial')}</span>
            {
              user.subscriptionEnd > (new Date()).getTime() / 1000 &&
              <span className="text-white fw-bold me-3 fs-7">{t('header.aiTokens')} {user.tokens}</span>
            }
          </div>
          <Link to="/app/payment">
            <MDBBtn rounded className="fw-bold fs-6 cta-btn">
              {t('header.cta')}
              <MDBIcon fas icon="crown" className="ms-2"/>
            </MDBBtn>
          </Link>
        </div>
      }
      </div>
    </MDBNavbar>
  );
};

export default Header;