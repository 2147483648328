import { MDBBtn, MDBBtnGroup, MDBCheckbox, MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { SOCIAL_ACCOUNTS } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  connectSocialAccount, getFacebookAccount, getInstagramAccount,
  getLinkedinAccount,
  getUserId, getUserRole,
  UserSocialMedia
} from '../../store/user.reducer';
import { ApiClient } from "../../api_client";
import { toggleMainLoader } from "../../store/app.reducer";
import { useTranslation } from 'react-i18next';
import FacebookLoginButton from '../facebook_login_button/facebook_login_button';
import { toast } from 'react-toastify';
import InstagramLoginButton from '../instagram_login_button/instagram_login_button';
import { getSelectedClient } from '../../store/manager.reducer';

export const SocialMediaConnect = (props: any) => {
  const [socialAccounts, setSocialAccounts]: [any, any] = useState(SOCIAL_ACCOUNTS);
  const [selectedSocialMedia, setSelectedSocialMedia]: [any, any] = useState({
    linkedin: false,
    facebook: false,
    instagram: false
  });

  const userRole = useSelector(getUserRole);
  const selectedClient = useSelector(getSelectedClient);
  const [managedUser, setManagedUser]: any = useState();
  const linkedinAccount = useSelector(getLinkedinAccount);
  const facebookAccount = useSelector(getFacebookAccount);
  const instagramAccount = useSelector(getInstagramAccount);
  const userId = useSelector(getUserId);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    validateSocialMediaAuthCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (userRole === 'manager' && selectedClient) {
      setManagedUser(selectedClient);
    } else if (userId) {
      setManagedUser(userId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, userId]);

  useEffect(() => {
    let accountsToUpdate = {};
    if (linkedinAccount.isConnected) {
      accountsToUpdate = {
        linkedin: linkedinAccount
      };
    }
    if (facebookAccount.isConnected) {
      accountsToUpdate = {
        ...accountsToUpdate,
        facebook: facebookAccount
      };
    }
    if (instagramAccount.isConnected) {
      accountsToUpdate = {
        ...accountsToUpdate,
        instagram: instagramAccount
      };
    }

    setSocialAccounts((state: any) => ({
      ...state,
      ...accountsToUpdate
    }));

    setSelectedSocialMedia({
      ...selectedSocialMedia,
      linkedin: linkedinAccount.isConnected,
      facebook: facebookAccount.isConnected,
      instagram: facebookAccount.isConnected
    });
    // TODO refactor this part
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkedinAccount, facebookAccount, instagramAccount]);

  useEffect(() => {
    props.selectSocialMedia(selectedSocialMedia);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSocialMedia])

  const validateSocialMediaAuthCode = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    const provider = queryParams.get('state');

    if (code && userId && provider) {
      storeLinkedinAuth(code, provider);
    }
  };

  const storeLinkedinAuth = (code: string, provider: string) => {
    dispatch(toggleMainLoader({
      isVisible: true
    }));
    ApiClient.storeSocialMediaAccount({
      user: userId,
      provider,
      code,
      redirectUri: window.location.href.split('?')[0]
    }).then(response => {
      dispatch(connectSocialAccount({
        provider,
        ...response?.data
      }));
      toast.success(t('settings.connectSocialAccountSuccess', {provider}));
    }).catch(err => {
      console.log(err);
      toast.error(t('settings.connectSocialAccountError', {provider}));
    }).finally(() => {
      dispatch(toggleMainLoader({
        isVisible: false
      }));
    })
  }

  const storeFacebookAuth = (response: any) => {
    dispatch(toggleMainLoader({
      isVisible: true
    }));
    const mappedData = {
      user: userId,
      provider: 'facebook',
      firstName: response.name,
      lastName: '',
      avatar: response.picture?.data?.url,
      headline: response.category || '',
      externalId: response.id,
      accessToken: response.access_token,
      accessTokenExpirationTS: null,
      refreshToken: null,
      refreshTokenExpirationTS: null
    }

    ApiClient.storeSocialMediaAccount(mappedData).then(response => {
      dispatch(connectSocialAccount({
        provider: 'facebook',
        ...response?.data
      }));
      toast.success(t('settings.connectSocialAccountSuccess', {provider: 'Facebook'}));
    }).catch(err => {
      console.log(err);
      toast.error(t('settings.connectSocialAccountError', {provider: 'Facebook'}));
    }).finally(() => {
      dispatch(toggleMainLoader({
        isVisible: false
      }))
    })
  }

  const storeInstagramAuth = (response: any) => {
    dispatch(toggleMainLoader({
      isVisible: true
    }));
    const mappedData = {
      user: userId,
      provider: 'instagram',
      firstName: response.name,
      lastName: '',
      avatar: response.profilePictureUrl,
      headline: response.username,
      externalId: response.id,
      accessToken: response.token
    }

    ApiClient.storeSocialMediaAccount(mappedData).then(response => {
      dispatch(connectSocialAccount({
        provider: 'instagram',
        ...response?.data
      }));
      toast.success(t('settings.connectSocialAccountSuccess', {provider: 'Instagram'}));
    }).catch(err => {
      console.log(err);
      toast.error(t('settings.connectSocialAccountError', {provider: 'Instagram'}));
    }).finally(() => {
      dispatch(toggleMainLoader({
        isVisible: false
      }))
    })
  }

  // Social media selection card
  const socialAccountAvatar = (socialAccountSelection: UserSocialMedia) => {
    if (socialAccountSelection?.isConnected) {
      return (
        <MDBCol className="col-4">
          <MDBCheckbox
            wrapperClass="social-media-card"
            labelClass="w-100"
            btn btnColor="secondary" id={socialAccountSelection.provider}
            value={socialAccountSelection.provider}
            name="socialAccount"
            checked={selectedSocialMedia[socialAccountSelection.provider]}
            onChange={() => {
              setSelectedSocialMedia({
                ...selectedSocialMedia,
                [socialAccountSelection.provider]: !selectedSocialMedia[socialAccountSelection.provider]
              });
            }}
            label={
              <div className="avatar-wrapper">
                <div className="avatar-pic">
                  <img src={socialAccountSelection.avatar} alt="" onError={(e: any) => {
                    e.target.src = `${process.env.PUBLIC_URL}/assets/images/avatar.png`;
                  }}/>
                </div>
                <div className="profile-text mt-4">
                  <span
                    className="fw-bold">{`${socialAccountSelection.firstName} ${socialAccountSelection.lastName}`}</span>
                  <br/>
                  <span className="m-0 text-capitalize fst-italic fs-6">{socialAccountSelection.provider}</span>
                </div>
              </div>
            }/>
        </MDBCol>
      )
    }

    return (
      <MDBCol className="col-4">
        <div className="social-media-card">
          <div className="avatar-wrapper text-center">
            <div className="avatar-pic">
              <MDBIcon fab style={{color: socialAccountSelection.iconColor}} icon={socialAccountSelection.icon}
                       size="2x"/>
            </div>
            {managedUser === userId && socialMediaConnectButton(socialAccountSelection.provider)}
          </div>
        </div>
      </MDBCol>
    )
  }

  const socialMediaConnectButton = (provider?: string) => {

    if (provider === 'linkedin') {
      const linkedinScopes = encodeURIComponent([
        'w_member_social',
        'r_basicprofile',
        'r_organization_social',
        'rw_organization_admin',
        'w_organization_social'
      ].join(' '));
      const linkedinAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_APP_ID}&redirect_uri=${encodeURIComponent(window.location.href.split('?')[0])}&scope=${linkedinScopes}&state=${provider}`;

      return (
        <a href={linkedinAuthUrl} key="ln">
          <MDBBtn className="mt-4 fw-bold w-100" color="primary" rippleColor="light" size="lg" outline rounded>
            {t('shared.connect')}
          </MDBBtn>
        </a>
      )
    }

    if (provider === 'instagram') {
      return (
        <InstagramLoginButton key="ig"
          loginCallback={(response) => {
            storeInstagramAuth(response);
          }}
        />
      )
    }

    if (provider === 'facebook') {
      return (
        <FacebookLoginButton key="fb"
          loginCallback={(response) => {
            storeFacebookAuth(response);
          }}
        />
      )
    }

    return (
      <MDBBtn className="mt-4 fw-bold w-100" color="tertiary" rippleColor="light" size="lg" outline rounded disabled>
        {t('shared.comingSoon')}
      </MDBBtn>
    )
  }

  return (
    <>
      <MDBCol className="col-12">
        <MDBRow className="row-cols-sm-12">
          <p className="my-2">
            {t('newPost.selectSocialMedia')}
          </p>
        </MDBRow>
      </MDBCol>
      <MDBCol className="col-12 my-3">
        <MDBBtnGroup className="row d-flex social-media-selector radio-selectors" shadow="0">
          {socialAccountAvatar(socialAccounts.linkedin)}
          {socialAccountAvatar(socialAccounts.facebook)}
          {socialAccountAvatar(socialAccounts.instagram)}
        </MDBBtnGroup>
      </MDBCol>
    </>
  )
}
