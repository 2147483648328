import React, { useEffect, useState } from 'react';
import { POST_INPUTS } from '../../constants';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/user.reducer';

const TopicsList = (props: any) => {

  const user = useSelector(getUser);

  const { t } = useTranslation();

  const [topic, setTopic]: [any, any] = useState('');

  useEffect(() => {
    if (topic) {
      props.propagateTopic(topic);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic]);

  useEffect(() => {
    if (user.profile) {
      setTopic(user.profile.topic);
    }
  }, [user.profile]);

  const sortTopics = (topics: any) => {
    return Object.keys(topics)
      .sort((topicA, topicB) => topics[topicA].placeholder.localeCompare(topics[topicB].placeholder))
      .reduce((sorted: any, topicKey) => {
        sorted[topicKey] = topics[topicKey];
        return sorted;
      }, {});
  }

  const renderTopicsList = () => {
    const sortedTopics = sortTopics(POST_INPUTS.mainFields);

    return (
      <select className="w-100" defaultValue="" key="topic-selector" value={topic} onChange={(e) => setTopic(e.target.value)}>
        <option value="" disabled>
          {t('newPost.chooseTopic')}
        </option>
        {
          Object.keys(sortedTopics).map((option: string, index: number) => {
            return <option value={option} key={index}>{t(`formBuilder.${option}`)}</option>
          })
        }
      </select>
    )
  }

  return (
    <>
      {renderTopicsList()}
    </>
  );
};

export default TopicsList;