import React, { useState } from 'react';
import { getUserEmail, getUserId, logoutUser } from '../../shared/store/user.reducer';
import { ApiClient } from '../../shared/api_client';
import {
  MDBBtn, MDBCard, MDBCardBody,
  MDBCardTitle,
  MDBCol,
  MDBInput,
  MDBModal, MDBModalBody,
  MDBModalContent,
  MDBModalDialog, MDBModalFooter,
  MDBModalHeader, MDBModalTitle,
  MDBRow
} from 'mdb-react-ui-kit';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';
import { toggleMainLoader } from '../../shared/store/app.reducer';
import { useTranslation } from 'react-i18next';
import Profile from '../../shared/parts/profile/profile';

const GeneralSettings = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const userEmail = useSelector(getUserEmail);
  const userId = useSelector(getUserId);

  const [deletionModalVisibility, setDeletionModalVisibility] = useState(false);
  const [deletionConfirmationModalVisibility, setDeletionConfirmationModalVisibility] = useState(false);
  const [profileModalVisibility, setProfileModalVisibility] = useState(false);

  const deleteAccount = () => {
    dispatch(toggleMainLoader({
      isVisible: true
    }));
    ApiClient.deleteUserAccount(userId)
      .then(() => {
        setDeletionConfirmationModalVisibility(true);
      })
      .catch(() => {
        toast.error(t('shared.errors.unexpected'))
      })
      .finally(() => {
        dispatch(toggleMainLoader({
          isVisible: false
        }))
      })
    setDeletionModalVisibility(false);
  }

  const accountDeletionConfirmation = () => {
    Auth.signOut()
      .then(() => {
        dispatch(logoutUser());
        navigate(0)
      });
  }

  const accountDeletionModal = () => {
    return (
      <>
        <MDBModal tabIndex="-1" show={deletionModalVisibility} setShow={setDeletionModalVisibility}>
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>
                  {t('settings.accountDeletionModal.title')}
                </MDBModalTitle>
                <MDBBtn className="btn-close" color="none" onClick={() => setDeletionModalVisibility(false)}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <p>
                  {t('settings.accountDeletionModal.content')}
                </p>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={() => setDeletionModalVisibility(false)}>
                  {t('shared.cancel')}
                </MDBBtn>
                <MDBBtn color="danger" onClick={() => deleteAccount()}>
                  {t('shared.delete')}
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </>
    )
  }

  const deletionValidationModal = () => {
    return (
      <>
        <MDBModal tabIndex="-1" show={deletionConfirmationModalVisibility}
                  setShow={setDeletionConfirmationModalVisibility}>
          <MDBModalDialog centered>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>
                  {t('settings.accountDeletedModal.title')}
                </MDBModalTitle>
                <MDBBtn className="btn-close" color="none" onClick={() => setDeletionModalVisibility(false)}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <p>
                  {t('settings.accountDeletedModal.content')}
                </p>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="success" onClick={() => accountDeletionConfirmation()}>
                  {t('settings.accountDeletedModal.bye')}
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </>
    )
  }

  return (
    <MDBCard>
      { profileModalVisibility && <Profile isVisible={true} onHide={() => { setProfileModalVisibility(false); }} /> }
      <MDBCardBody>
        <MDBRow className="row-cols-sm-12">
          <MDBCardTitle className="mt-0 fs-4 mb-4">
            {t('settings.miscellaneous')}
          </MDBCardTitle>
        </MDBRow>
        <MDBRow>
          <MDBCol className="col-12">
            <div className="d-flex mb-4">
              <div className="d-flex align-items-center me-2">
                <span className="fw-bold">
                  {t('settings.myProfile')}
                </span>
              </div>
              <MDBBtn className="" size="lg" onClick={() => {
                setProfileModalVisibility(true)
              }}>
                {t('settings.profileSetup')}
              </MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="col-12">
            <MDBInput disabled label="Email" value={userEmail}/>
            <Link to="" onClick={() => setDeletionModalVisibility(true)}>
              {t('settings.deleteAccount')}
            </Link>
          </MDBCol>
        </MDBRow>
        {accountDeletionModal()}
        {deletionValidationModal()}
      </MDBCardBody>
    </MDBCard>
  );
};

export default GeneralSettings;
