import React, { useEffect, useState } from 'react';
import {
  MDBCheckbox,
  MDBCol,
  MDBIcon,
  MDBInput,
  MDBRange,
  MDBTooltip
} from 'mdb-react-ui-kit';
import TagsInput from 'react-tagsinput';
import './fields_creator.css';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/user.reducer';

const FieldsCreator = (props: any) => {

  const {t} = useTranslation();

  const user = useSelector(getUser);

  const [formData, setFormData]: [any, any] = useState({});

  useEffect(() => {
    if (user.profile) {
      const data: any = {};

      Object.keys(user.profile)
        .filter(itemKey => itemKey !== 'topic')
        .forEach((itemKey: any) => {
          data[itemKey] = {
            value: user.profile[itemKey]
          }
        })

      setFormData((d: any) => {
        const updatedData = {
          ...d,
          advancedFields: {
            ...data
          }
        };
        props.propagateFormData(updatedData);
        return updatedData;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const updateFormData = (name: string, value: any, fieldGroup: string, isSensitive: boolean = false) => {
    // If value is empty, completely remove the field from formData state
    setFormData((data: any) => {
      if (value === '') {
        delete data[fieldGroup][name]
        return {...data};
      } else {
        return {
          ...data,
          [fieldGroup]: {
            ...data[fieldGroup],
            [name]: {
              value,
              isSensitive
            }
          }
        }
      }
    });
  }

  const handleInputChange = (event: any, fieldGroup: string, isSensitive: boolean = false) => {
    const {name, value} = event.target;
    updateFormData(name, value, fieldGroup, isSensitive);
    props.propagateInputData(name, value, fieldGroup, isSensitive);
  };

  const handleCheckboxChange = (event: any, fieldGroup: string) => {
    const {name} = event.target;
    const value = formData[fieldGroup]?.[name]?.value === 'true' ? 'false' : 'true';
    updateFormData(name, value, fieldGroup);
    props.propagateInputData(name, value, fieldGroup);
  };

  const handleTagsChange = (value: string[], name: string, fieldGroup: string) => {
    updateFormData(name, value, fieldGroup);
    props.propagateInputData(name, value, fieldGroup);
  }

  const InputTypes = {
    text: 'text',
    select: 'select',
    date: 'date',
    tag: 'tag',
    number: 'number',
    checkboxList: 'checkboxList',
    range: 'range',
  };

  const dropdownBuilder = (data: any, fieldName: string, fieldGroup: string) => {

    return (
      <select className="w-100" name={fieldName} defaultValue="" key={fieldName}
              value={formData[fieldGroup]?.[fieldName]?.value || ''}
              onChange={(event) => handleInputChange(event, fieldGroup)}>
        <option value="" disabled> {t(`formBuilder.${fieldName}`)} </option>
        {
          data.list.map((option: any, index: number) => {
            return <option value={option.value} key={index}>{t(`formBuilder.${option.value}`)}</option>
          })
        }
      </select>
    )
  }

  const checkboxListBuilder = (data: any, fieldName: string, fieldGroup: string) => {
    return (
      data.list.map((checkbox: any, index: number) => {
        const id = Math.random();
        return <MDBCheckbox
          key={index}
          label={t(`formBuilder.${checkbox.name}`)}
          name={checkbox.name}
          id={`${data.id}-${checkbox.name}-${id}`}
          onChange={(event) => handleCheckboxChange(event, fieldGroup)}
          checked={formData[fieldGroup]?.[checkbox.name]?.value === 'true'}
        />
      })
    )
  }

  const createFields = (fields: any, fieldGroup: string) => {
    return Object.entries(fields)
      .filter(([fieldName]) => !props.fieldsToCreate || (props.fieldsToCreate && props.fieldsToCreate.includes(fieldName)))
      .map(([fieldName, fieldData]: [any, any]) => {
        const {type, length, sensitive} = fieldData;
        const placeholder = t(`formBuilder.${fieldName}`);
        const inputId = `${fieldName}`;
        let render;
        const value = formData[fieldGroup] && formData[fieldGroup][fieldName] ? formData[fieldGroup][fieldName].value : '';

        switch (type) {
          case InputTypes.text:
            render = (
              <div className={`position-relative ${sensitive ? 'private-field' : ''}`}>
                <MDBInput
                  className={`${sensitive ? 'pe-5' : ''}`}
                  key={inputId}
                  label={placeholder}
                  id={inputId}
                  name={fieldName}
                  type="text"
                  maxLength={length}
                  onChange={(event) => handleInputChange(event, fieldGroup, sensitive)}
                  value={value || ''}
                />
                {(sensitive &&
									<MDBTooltip tag="div" placement="top" title={t('formBuilder.securedField')}>
										<MDBIcon fas icon="lock"/>
									</MDBTooltip>)}
              </div>
            );
            break;
          case InputTypes.select:
            render = dropdownBuilder(fieldData, fieldName, fieldGroup)
            break;
          case InputTypes.checkboxList:
            render = checkboxListBuilder(fieldData, fieldName, fieldGroup)
            break;
          case InputTypes.date:
            render = (
              <MDBInput
                key={inputId}
                label={placeholder}
                id={inputId}
                name={fieldName}
                type="date"
                onChange={(event) => handleInputChange(event, fieldGroup)}
                value={value || ''}
              />
            );
            break;
          case InputTypes.tag:
            const tags = formData[fieldGroup] && formData[fieldGroup][fieldName] ? formData[fieldGroup][fieldName].value : [];
            render = (
              <div className={`position-relative ${sensitive ? 'private-field' : ''}`}>

                <TagsInput value={tags} onChange={(tags: string[]) => {
                  handleTagsChange(tags, fieldName, fieldGroup)
                }} inputProps={{placeholder}}/>
                {(sensitive &&
									<MDBTooltip tag="div" placement="top" title="This field will not be shared with our AI partners.">
										<MDBIcon fas icon="lock"/>
									</MDBTooltip>)}
              </div>
            );
            break;
          case InputTypes.number:
            render = (
              <MDBInput
                key={inputId}
                label={placeholder}
                id={inputId}
                name={fieldName}
                type="number"
                maxLength={length}
                onChange={(event) => handleInputChange(event, fieldGroup)}
                value={value || ''}
              />
            );
            break;
          case InputTypes.range:
            render = (
              <div className="range-wrapper mb-3">
                <span className="low-label">{t(`formBuilder.${fieldData.minLabel}`)}</span>
                <span className="mid-label">{t(`formBuilder.${fieldData.midLabel}`)}</span>
                <span className="high-label">{t(`formBuilder.${fieldData.maxLabel}`)}</span>
                <MDBRange
                  onChange={(event) => handleInputChange(event, fieldGroup)}
                  value={(formData[fieldGroup] && formData[fieldGroup][fieldName]) ? formData[fieldGroup][fieldName].value : ''}
                  key={inputId}
                  label={placeholder}
                  id={inputId}
                  name={fieldName}
                  min={fieldData.min}
                  max={fieldData.max}
                  defaultValue={fieldData.defaultValue}
                  step={fieldData.step}
                  disableTooltip={true}
                />
              </div>
            );
            break;
          default:
            return null;
        }

        return (
          <MDBCol className="mt-4" key={fieldName}>
            {render}
          </MDBCol>
        );
      })
  }

  return (
    <>
      {createFields(props.fields, props.fieldsGroup)}
    </>
  );
};

export default FieldsCreator;