import './profile.css';
import React, { useEffect, useRef, useState } from 'react';
import {
  MDBBtn,
  MDBCol, MDBFile, MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog, MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle, MDBRow
} from 'mdb-react-ui-kit';
import FieldsCreator from '../fields_creator/fields_creator';
import { IS_VALID_LOGO, POST_INPUTS } from '../../constants';
import { ApiClient } from '../../api_client';
import { useSelector } from 'react-redux';
import { getUser, getUserId } from '../../store/user.reducer';
import TopicsList from '../topics_list/topics_list';
import { useTranslation } from 'react-i18next';
import ColorPick from '../color_picker/color_picker';
import { toast } from 'react-toastify';
import axios from 'axios';

const Profile = ({isVisible, onHide}: any) => {
  const [profileModalVisibility, setProfileModalVisibility] = useState(isVisible);
  const [profileData, setProfileData]: [any, any] = useState({});

  const {t} = useTranslation();

  const user = useSelector(getUser);
  const userId = useSelector(getUserId);

  const fileAttachmentRef = useRef<any>(null);

  const [brandLogoFile, setBrandLogoFile]: [any, any] = useState(null);
  const [imageSource, setImageSource]: [any, any] = useState();
  const [pendingRequest, setPendingRequest]: [any, any] = useState(false);

  useEffect(() => {
    if (user.profile) {
      setProfileData(user.profile);
      if (user.profile.brandLogo) {
        setImageSource(`${process.env.REACT_APP_ASSETS_CDN}/${userId}/brand/${user.profile.brandLogo}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.profile]);

  const updateProfile = () => {
    setPendingRequest(true);
    // setup logo file info only if a new image is selected
    const brandLogoParams = brandLogoFile ? {
      fileName: brandLogoFile.name,
      size: brandLogoFile.size,
      type: brandLogoFile.type
    } : null;

    ApiClient.setUserProfile(userId, profileData, brandLogoParams)
      .then(async (response) => {
        if (response.data.logoUploadUrl) {
          await axios.put(response.data.logoUploadUrl, brandLogoFile, {
            headers: {
              'Content-Type': brandLogoFile.type
            }
          })
        }
        setProfileModalVisibility(false);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setPendingRequest(false);
      })
  };

  const updateFormData = (fieldsData: any) => {
    let formattedData = {};
    Object.keys(fieldsData?.advancedFields).forEach((key: any) => {
      formattedData = {
        ...formattedData,
        [key]: fieldsData?.advancedFields[key].value
      }
    })
    setProfileData((oldFormData: any) => ({
      ...oldFormData,
      ...formattedData,
    }))
  }

  const updateInputData = (name: string, value: any, fieldGroup: string, isSensitive: boolean = false) => {
    // If value is empty, completely remove the field from formData state
    setProfileData((data: any) => {
      return {
        ...data,
        [name]: value
      }
    })
  }

  const handleAttachmentFileChange = (event: any) => {
    const file = event.target.files[0];

    if (IS_VALID_LOGO(file.name)) {
      setImageSource(URL.createObjectURL(file));
      setBrandLogoFile(file);
    } else {
      toast.error(t('postPreview.errors.fileFormat'));
      clearFileAttachment();
    }
  }

  const clearFileAttachment = () => {
    if (fileAttachmentRef.current) {
      fileAttachmentRef.current.value = null;
    }
    setImageSource(null);
    setBrandLogoFile(null);
  }

  return (
    <>
      <MDBModal tabIndex="-1"
                show={profileModalVisibility}
                setShow={setProfileModalVisibility}
                onHide={() => {
                  onHide();
                }}
      >
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                {t('profileSetup.title')}
              </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow>
                <MDBCol className="fw-bold mb-3">
                  {t('profileSetup.visualIdentity')}
                </MDBCol>
              </MDBRow>
              <MDBRow className="row-cols-sm-2">
                <MDBCol className="align-content-center">
                  <MDBRow className="row-cols-sm-2 mb-2">
                    <MDBCol className="align-content-center">
                      {t('profileSetup.primaryColor')}
                    </MDBCol>
                    <MDBCol className="align-items-center text-end">
                      <ColorPick defaultColor={user?.profile?.primaryColor || null}
                                 label={t('profileSetup.choose')}
                                 propagateColor={(color: string) => setProfileData((data: any) => ({
                                   ...data,
                                   primaryColor: color
                                 }))}/>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="row-cols-sm-2 mb-3">
                    <MDBCol className="align-content-center">
                      {t('profileSetup.secondaryColor')}
                    </MDBCol>
                    <MDBCol className="align-items-center text-end">
                      <ColorPick defaultColor={user?.profile?.secondaryColor || null}
                                 label={t('profileSetup.choose')}
                                 propagateColor={(color: string) => setProfileData((data: any) => ({
                                   ...data,
                                   secondaryColor: color
                                 }))}/>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                <MDBCol>
                  <MDBRow className="brand-logo-wrapper mb-3">
                    <MDBCol className="position-relative">
                      <MDBFile inputRef={fileAttachmentRef} id="brand-logo" onChange={handleAttachmentFileChange}
                               accept="image/*,video/*"
                               className="fs-7"
                      />
                      <MDBRow className="row-cols-sm-2">
                        <MDBCol className="align-content-center">
                          Logo :
                        </MDBCol>
                        <MDBCol className="text-end d-flex flex-column align-items-end">
                          <div className="user-logo"
                               style={{
                                 backgroundImage: imageSource
                                   ? `url('${imageSource}')`
                                   : `url('${process.env.PUBLIC_URL}/assets/images/photo-icon.png')`
                               }}/>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>


              <MDBRow>
                <MDBCol className="fw-bold mt-1 mb-3">
                  {t('profileSetup.userProfile')}
                </MDBCol>
              </MDBRow>
              <MDBRow className="row-cols-sm-2">
                <MDBCol>
                  <TopicsList propagateTopic={(topic: any) => {
                    setProfileData((data: any) => ({
                      ...data,
                      topic
                    }));
                  }}/>
                </MDBCol>
                <MDBCol>
                  <select className="w-100" name="outputPostLanguage"
                          defaultValue=''
                          value={user?.profile?.outputPostLanguage}
                          onChange={(event) => {
                            updateInputData(event.target.name, event.target.value, 'mainFields')
                          }}>
                    <option value="" disabled>
                      {t('newPost.chooseLanguage')}
                    </option>
                    <option value="english">{t(`shared.english`)}</option>
                    <option value="french">{t(`shared.french`)}</option>
                  </select>
                </MDBCol>
              </MDBRow>
              <MDBRow className="row-cols-sm-2">
                <FieldsCreator fields={POST_INPUTS.advancedFields} fieldsGroup="advancedFields"
                               fieldsToCreate={['role', 'tone', 'format', 'pronoun', 'emojis', 'creativity', 'parameters']}
                               propagateInputData={updateInputData}
                               propagateFormData={updateFormData}
                />
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBRow>
                <MDBCol className="pe-0">
                  <MDBBtn className="btn-with-loader w-100 px-5" size="lg" color="primary" type="submit" rounded
                          onClick={updateProfile}
                          disabled={pendingRequest}>
                    {t('profileSetup.save')}
                    {pendingRequest &&
											<div className="loader-icon fs-5">
												<MDBIcon fas icon="circle-notch"/>
											</div>
                    }
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );

}

export default Profile;